import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import TableHeader from "components/Table/TableHeader";
import TableElement from "components/Table/TableElement";
import { getAllUsers } from "apis/UsersApi";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  useMediaQuery,
} from "@mui/material";
import ExcelExport from "components/ExcelExport/ExcelExport";
import { useDispatch, useSelector } from "react-redux";
import { setLoadingPanel } from "../../redux/features/loading";
import CircularIndeterminateModal from "../../utils/Loading";

export default function Users() {
  const color = "light";
  const [users, setUsers] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [payload, setPayload] = useState({
    page: 0,
    size: 50,
    state: "",
  });
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [userSize, setUserSize] = useState(25);
  const [excelData, setExcelData] = useState([]);
  const [openExcelDialog, setOpenExcelDialog] = useState(false);
  const isLoading = useSelector((state) => state.loadingReducer.isLoading);
  const dispatch = useDispatch();
  const isMobile = useMediaQuery("(max-width:600px)");
  const today = new Date();
  const fileName = `users_data_${today.getDate()}${
    today.getMonth() + 1
  }${today.getFullYear()}_${today.getHours()}${today.getMinutes()}`;

  const getUsers = (
    page = payload.page,
    size = payload.size,
    state = payload.state
  ) => {
    if (isInitialLoad) dispatch(setLoadingPanel(true));
    getAllUsers({ page, size, state })
      .then((res) => {
        setUsers(res.data.data);
        setTotalPages(Math.ceil(res.data.totalElements / size));
        const excelDataArray = res.data.data.map((user, index) => ({
          SNo: index + 1,
          Name: user?.name,
          Email: user?.email,
          Mobile: user?.mobile,
          BusinessName: user?.business?.name,
          GSTNumber: user?.business?.gstNumber,
          City: user?.business?.address?.city,
          State: user?.business?.address?.state?.name,
        }));
        dispatch(setLoadingPanel(false));
        setExcelData(excelDataArray);
      })
      .catch((err) => {
        if (err?.response?.data) {
          toast(err.response.status + " " + err?.response?.data?.message, {
            type: "error",
            position: toast.POSITION.TOP_CENTER,
            autoClose: 1000,
          });
        } else {
          toast(err?.response?.data?.message, {
            type: "error",
            position: toast.POSITION.TOP_CENTER,
            autoClose: 1000,
          });
        }
      })
      .finally(() => {
        if (isInitialLoad) setIsInitialLoad(false);
      });
  };

  useEffect(() => {
    if (isInitialLoad) {
      dispatch(setLoadingPanel(true));
    }
    getUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payload]);

  const handlePageChange = (event, value) => {
    setPayload((prev) => ({
      ...prev,
      page: value - 1, // Pagination component is 1-based, API is 0-based
    }));
  };

  const handleStateChange = (event) => {
    setPayload((prev) => ({
      ...prev,
      state: event.target.value,
      page: 0, // Reset to first page when filter changes
    }));
  };

  const handleClickOpenExcelDialog = () => {
    setOpenExcelDialog(true);
  };

  const handleCloseExcelDialog = () => {
    setOpenExcelDialog(false);
  };

  const handleUserSizeChange = (e) => {
    const size = e.target.value;
    setUserSize(size);
    getUsers(0, size); // Fetch the first page of users with the new size
  };

  return (
    <>
      <CircularIndeterminateModal open={isLoading} />
      <div
        className={
          "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded " +
          (color === "light" ? "bg-white" : "bg-lightBlue-900 text-white")
        }
      >
        <div className="flex justify-center my-4">
          <Stack spacing={2}>
            <Pagination
              count={totalPages}
              page={payload.page + 1}
              color="primary"
              onChange={handlePageChange}
              siblingCount={0}
              boundaryCount={isMobile ? 1 : 2}
            />
          </Stack>
        </div>{" "}
        <div className="flex justify-center lg:justify-end mx-4">
          <button
            className="px-2 py-1 md:p-4 text-xs sm:text-[13px] lg:text-[16px] bg-blue-500 text-white rounded"
            onClick={handleClickOpenExcelDialog}
          >
            EXPORT
          </button>
          <Dialog
            open={openExcelDialog}
            onClose={handleCloseExcelDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              Export Subscription Data
            </DialogTitle>
            <DialogContent>
              You are Downloading subscription data of{" "}
              <TextField
                id="filled-basic"
                onChange={handleUserSizeChange}
                variant="standard"
                sx={{ width: "60px" }}
                value={userSize}
              />{" "}
              users
            </DialogContent>
            <DialogActions>
              <ExcelExport excelData={excelData} fileName={fileName} />
            </DialogActions>
          </Dialog>
        </div>
        <div className="block w-full overflow-x-auto py-3 px-5 lg:p-8">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-100">
              <tr className="divide-x divide-gray-200 ">
                <TableHeader heading="S No." />
                <TableHeader heading="Name" />
                <TableHeader heading="Email" />
                <TableHeader heading="Mobile" />
                <TableHeader heading="Business" />
                <TableHeader heading="Business Address" />
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white">
              {users.map((user, i) => {
                return (
                  <tr
                    key={i}
                    className="divide-x divide-gray-200 hover:bg-slate-100"
                  >
                    <TableElement value={i + 1} />
                    <TableElement
                      value={
                        <span className="font-semibold">{user?.name}</span>
                      }
                      type="element"
                    />
                    <TableElement value={user?.email} />
                    <TableElement value={user?.mobile} />
                    <TableElement
                      value={
                        <div className="flex flex-col">
                          <span>Name:- {user?.business?.name}</span>
                          <span>GST:- {user?.business?.gstNumber}</span>
                        </div>
                      }
                    />
                    <TableElement
                      value={
                        <div className="flex flex-col">
                          <span>City:- {user?.business?.address?.city}</span>
                          <span>
                            State:- {user?.business?.address?.state?.name}
                          </span>
                        </div>
                      }
                      type="element"
                    />
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}
