import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    subscribed: false,
};

export const subscribed = createSlice({
    name: 'subscribed',
    initialState: initialState,
    reducers: {
        setSubscribed: (state, action) => {
            state.subscribed = action.payload || false;
        },
    },
});

export const { setSubscribed } = subscribed.actions;

export default subscribed.reducer;
