import React, { useEffect, useState } from "react";
import { Button } from "@mui/material";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import CloseIcon from "@mui/icons-material/Close";
import { TotpAPI } from "../../apis/totp";

const TOTP = () => {
  const navigate = useNavigate();
  const [totp, setTotp] = useState({ otp: "", seconds: 30 });

  const getTOTP = () => {
    TotpAPI.GetTOTP()
      .then((res) => {
        setTotp(res.data.data);
      })
      .catch((err) => {
        if (err?.response?.data) {
          toast(err.response.status + " " + err?.response?.data?.message, {
            type: "error",
            position: toast.POSITION.TOP_CENTER,
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            style: {
              maxWidth: "90vw",
              width: "auto",
              margin: "5px  auto",
              padding: "5px 5px",
              fontSize: "14px",
            },
            closeButton: (
              <div className="flex justify-end">
                <button className="text-gray-500 hover:text-gray-800 focus:outline">
                  <CloseIcon className="w-7 h-7" />
                </button>
              </div>
            ),
          });
        } else {
          toast(err?.response?.data?.message, {
            type: "error",
            position: toast.POSITION.TOP_CENTER,
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            style: {
              maxWidth: "90vw",
              width: "auto",
              margin: "5px  auto",
              padding: "5px 5px",
              fontSize: "14px",
            },
            closeButton: (
              <div className="flex justify-end">
                <button className="text-gray-500 hover:text-gray-800 focus:outline">
                  <CloseIcon className="w-7 h-7" />
                </button>
              </div>
            ),
          });
        }
        if (err.response.status === 401) {
          localStorage.clear();
          navigate("/");
        }
      });
  };

  useEffect(() => {
    getTOTP();
    return () => {};
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (totp.seconds > 88) {
        getTOTP();
      } else {
        setTotp({ ...totp, seconds: totp.seconds + 1 });
      }
    }, 1000);
    return () => clearInterval(intervalId); //This is important
  }, [totp]);

  return (
    <div
      className={
        "relative flex flex-col justify-center items-center min-w-full break-words w-full h-[70dvh] mb-6 shadow-lg rounded bg-white"
      }
    >
      <div className="flex items-center w-1/5 flex-col md:flex-row justify-evenly">
        <span className="text-lg my-4">{totp.otp} </span>
        <span
          className={
            "text-lg my-4 " +
            (totp.seconds > 80 ? "text-red-500 " : "text-blue-300")
          }
        >
          {totp.seconds}
        </span>
      </div>

      <Button
        onClick={(e) => {
          navigator.clipboard.writeText(totp.otp);
          toast("Copied....", {
            type: "success",
            position: toast.POSITION.TOP_CENTER,
            autoClose: 500,
          });
        }}
        type="text"
        size="large"
      >
        COPY
      </Button>
    </div>
  );
};

export default TOTP;
