import axios from "axios";
import { EMI_BOOK } from "./constants";
import { getHeaders } from "./head";

export const IssueApi = {
  GetIssue: async () => {
    return await axios.get(`${EMI_BOOK}/issue`, {
      headers: getHeaders(true),
    });
  },
  GetIssueById: async (id) => {
    return await axios.get(`${EMI_BOOK}/issue/` + id, {
      headers: getHeaders(true),
    });
  },
  EditIssueById: async (id, payload) => {
    return await axios.put(`${EMI_BOOK}/issue/` + id, payload, {
      headers: getHeaders(true),
    });
  },
  CreateIssues(payload) {
    return axios.post(`${EMI_BOOK}/issue/`, payload, {
      headers: getHeaders(true),
    });
  },
  CreateIssuesMobile(payload, mobile) {
    return axios.post(`${EMI_BOOK}/issue/` + mobile, payload, {
      headers: getHeaders(true),
    });
  },
  DeleteIssues(id) {
    return axios.delete(`${EMI_BOOK}/issue/` + id, {
      headers: getHeaders(true),
    });
  },
  EditIssues(payload, mobile) {
    return axios.put(`${EMI_BOOK}/issue/` + mobile, payload, {
      headers: getHeaders(true),
    });
  },
};
