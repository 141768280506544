import React, { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import TableHeader from "components/Table/TableHeader";
import TableElement from "components/Table/TableElement";
import { getAllOrdersByFilter } from "apis/orders";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  FormControlLabel,
  Radio,
  Grid,
  FormLabel,
  RadioGroup,
  Divider,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { getInvoiceData } from "apis/orders";
import { formatDateTimeComponent } from "utils";
import { updateOrder } from "apis/orders";
import { useDispatch, useSelector } from "react-redux";
import { setLoadingPanel } from "../../redux/features/loading";
import CircularIndeterminateModal from "../../utils/Loading";

export default function AllOrders() {
  const color = "light";
  const isMobile = useMediaQuery("(max-width:600px)");
  const [orders, setOrders] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [openGenerateInvoice, setOpenGenerateInvoice] = useState(false);
  const [totalElements, setTotalElements] = useState("");
  const isLoading = useSelector((state) => state.loadingReducer.isLoading);
  const [generateLoad, setGenerateLoad] = useState(false);
  const dispatch = useDispatch();
  const [isSearching, setIsSearching] = useState(false);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [payload, setPayload] = useState({
    status: "COMPLETED",
    page: 0,
    size: 50,
    mobile: "",
    resellerMobile: "",
    isCreditOrder: null,
  });
  const [editOrderOpen, setEditOrderOpen] = useState(false);
  const [editPayload, setEditPayload] = useState({});
  const [invoice, setInvoice] = useState({
    id: null,
    invoiceNo: null,
  });

  const handleCloseGenerateInvoice = () => {
    setOpenGenerateInvoice(false);
  };

  const getOrders = useCallback(
    async (filters) => {
      if (isInitialLoad || isSearching) {
        dispatch(setLoadingPanel(true));
      }
      try {
        const { data } = await getAllOrdersByFilter(filters);
        setOrders(data.data);
        setTotalPages(data.pages);
        setTotalElements(data.totalElements);
        if (isInitialLoad) {
          setIsInitialLoad(false);
        }
        if (isSearching) {
          setIsSearching(false);
        }
        dispatch(setLoadingPanel(false));
      } catch (err) {
        if (isInitialLoad) {
          setIsInitialLoad(false);
        }
        if (isSearching) {
          setIsSearching(false);
        }
        dispatch(setLoadingPanel(false));
        toast(err.response?.status + " " + err?.response?.data?.message, {
          type: "error",
          position: toast.POSITION.TOP_CENTER,
          autoClose: 1000,
        });
      }
    },
    [dispatch, isInitialLoad, isSearching]
  );

  const handleUpdateOrder = async () => {
    try {
      await updateOrder(editPayload.id, editPayload);
      toast("Order Updated", {
        type: "success",
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1000,
      });
    } catch (err) {
      toast(err.response?.status + " " + err?.response?.data?.message, {
        type: "error",
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1000,
      });
    }
  };

  const fetchData = async () => {
    const isMobileValid = payload.mobile === "" || payload.mobile.length === 10;
    const isResellerMobileValid =
      payload.resellerMobile === "" || payload.resellerMobile.length === 10;
    if (isMobileValid && isResellerMobileValid) {
      const obj = {
        ...payload,
        mobile: payload.mobile.length === 10 ? payload.mobile : null,
        resellerMobile:
          payload.resellerMobile.length === 10 ? payload.resellerMobile : null,
      };
      await getOrders(obj);
    }
  };

  useEffect(() => {
    fetchData();
  }, [getOrders, payload]);

  const generateInvoice = () => {
    setGenerateLoad(true);
    getInvoiceData(invoice.id, invoice)
      .then((res) => {
        toast("Invoice created successfully", {
          type: "success",
          position: toast.POSITION.TOP_CENTER,
          autoClose: 1000,
        });
        setGenerateLoad(false);
        setOpenGenerateInvoice(false);
        fetchData();
      })
      .catch((err) => {
        setGenerateLoad(false);
        if (err?.response?.data) {
          toast(err.response.status + " " + err?.response?.data?.message, {
            type: "error",
            position: toast.POSITION.TOP_CENTER,
            autoClose: 1000,
          });
        } else {
          toast(err?.response?.data?.message, {
            type: "error",
            position: toast.POSITION.TOP_CENTER,
            autoClose: 1000,
          });
        }
      });
  };

  const handleGenerateInvoice = (row) => {
    setOpenGenerateInvoice(true);
    setInvoice({ ...invoice, id: row, invoiceNo: row });
  };

  const handlePageChange = (event, value) => {
    setPayload((prev) => ({
      ...prev,
      page: value - 1,
    }));
  };

  return (
    <>
      <CircularIndeterminateModal
        open={isLoading && (isInitialLoad || isSearching)}
      />{" "}
      <EditDialog
        setEditOrderOpen={setEditOrderOpen}
        editOrderOpen={editOrderOpen}
        setEditPayload={setEditPayload}
        editPayload={editPayload}
        handleUpdateOrder={handleUpdateOrder}
      />
      <div
        className={
          "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded " +
          (color === "light" ? "bg-white" : "bg-lightBlue-900 text-white")
        }
      >
        <div className="block w-full overflow-x-auto lg:p-8 py-3 px-5">
          <form>
            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <TextField
                  fullWidth
                  id="outlined-adornment-weight"
                  variant="standard"
                  label={
                    <span className="text-xs sm:text-sm md:text-base lg:text-lg font-medium">
                      Mobile Number
                    </span>
                  }
                  InputProps={{
                    sx: {
                      fontSize: {
                        xs: "12px",
                        sm: "14px",
                        md: "16px",
                        lg: "16px",
                      },
                    },
                  }}
                  onChange={(e) => {
                    if (e.target.value.length === 10) {
                      setIsSearching(true);
                    }
                    setPayload({
                      ...payload,
                      mobile: e.target.value,
                    });
                  }}
                  value={"" + payload.mobile}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  fullWidth
                  id="outlined-adornment-weight"
                  variant="standard"
                  label={
                    <span className="text-xs sm:text-sm md:text-base lg:text-lg font-medium">
                      Reseller Mobile Number
                    </span>
                  }
                  InputProps={{
                    sx: {
                      fontSize: {
                        xs: "12px",
                        sm: "14px",
                        md: "16px",
                        lg: "16px",
                      },
                    },
                  }}
                  onChange={(e) => {
                    if (e.target.value.length === 10) {
                      setIsSearching(true);
                    }
                    setPayload({
                      ...payload,
                      resellerMobile: e.target.value,
                    });
                  }}
                  value={"" + payload.resellerMobile}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControl fullWidth variant="standard">
                  <InputLabel
                    size="small"
                    id="demo-simple-select-label"
                    className="text-xs xl:text-sm"
                  >
                    Status
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={payload.status}
                    onChange={(event) => {
                      setIsSearching(true);
                      setPayload((prev) => ({
                        ...prev,
                        status: event.target.value,
                      }));
                    }}
                    sx={{
                      fontSize: {
                        xs: "12px",
                        sm: "13x",
                        md: "13px",
                        lg: "16px",
                      },
                    }}
                    label="Status"
                  >
                    <MenuItem
                      value="COMPLETED"
                      sx={{
                        fontSize: {
                          xs: "12px",
                          sm: "13x",
                          md: "13px",
                          lg: "16px",
                        },
                      }}
                    >
                      COMPLETED
                    </MenuItem>
                    <MenuItem
                      value="PAYMENT_FAILED"
                      sx={{
                        fontSize: {
                          xs: "12px",
                          sm: "13x",
                          md: "13px",
                          lg: "16px",
                        },
                      }}
                    >
                      PAYMENT FAILED
                    </MenuItem>
                    <MenuItem
                      value="PAYMENT_UPDATED"
                      sx={{
                        fontSize: {
                          xs: "12px",
                          sm: "13x",
                          md: "13px",
                          lg: "16px",
                        },
                      }}
                    >
                      PAYMENT UPDATE
                    </MenuItem>
                    <MenuItem
                      value="PAYMENT_REQUIRED"
                      sx={{
                        fontSize: {
                          xs: "12px",
                          sm: "13x",
                          md: "13px",
                          lg: "16px",
                        },
                      }}
                    >
                      PAYMENT REQUIRED
                    </MenuItem>
                    <MenuItem
                      value="CANCELLED"
                      sx={{
                        fontSize: {
                          xs: "12px",
                          sm: "13x",
                          md: "13px",
                          lg: "16px",
                        },
                      }}
                    >
                      CANCELLED
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid
                sx={{
                  padding: { lg: 4, xs: 2 },
                }}
              >
                <FormControl
                  sx={{
                    display: "flex",
                    flexDirection: { xs: "column", md: "column" },
                  }}
                >
                  <FormLabel
                    id="demo-controlled-radio-buttons-group"
                    sx={{
                      fontSize: {
                        xs: "12px",
                        sm: "13px",
                        md: "13px",
                        lg: "16px",
                      },
                    }}
                  >
                    Credit Order
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group"
                    name="Credit Order"
                    value={payload?.isCreditOrder}
                    onChange={(e) => {
                      setIsSearching(true);
                      setPayload({
                        ...payload,
                        isCreditOrder: e.target.value,
                      });
                    }}
                  >
                    <FormControlLabel
                      value={true}
                      control={
                        <Radio
                          sx={{
                            padding: "8px",
                            "& .MuiSvgIcon-root": {
                              fontSize: {
                                xs: "18px",
                                md: "22px",
                              },
                            },
                          }}
                        />
                      }
                      label={
                        <span className="text-xs sm:text-sm md:text-base lg:text-lg font-medium">
                          True
                        </span>
                      }
                    />
                    <FormControlLabel
                      value={false}
                      control={
                        <Radio
                          sx={{
                            padding: "8px",
                            "& .MuiSvgIcon-root": {
                              fontSize: {
                                xs: "18px",
                                md: "22px",
                              },
                            },
                          }}
                        />
                      }
                      label={
                        <span className="text-xs sm:text-sm md:text-base lg:text-lg font-medium">
                          False
                        </span>
                      }
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
          </form>

          <div className="text-center border flex xl:flex-row flex-col gap-3 justify-between p-2 mb-2">
            <div className="my-1">
              <Stack
                direction={{ xs: "column", sm: "column", md: "row" }}
                spacing={2}
                divider={<Divider orientation="vertical" flexItem />}
                sx={{ padding: 0 }}
              >
                <Stack
                  direction={{ xs: "row", sm: "row", md: "row" }}
                  spacing={2}
                  alignItems="center"
                  justifyContent="center"
                  divider={<Divider orientation="vertical" flexItem />}
                >
                  <div className="flex items-center">
                    <span className="text-sm font-extrabold align-baseline">
                      Total :- {totalElements}
                    </span>
                  </div>
                  <Button
                    variant="contained"
                    size="medium"
                    color="info"
                    sx={{
                      fontSize: {
                        xs: "12px",
                        sm: "13x",
                        md: "13px",
                        lg: "13px",
                      },
                      padding: { md: "6px 16px", xs: "2px 8px" },
                    }}
                    onClick={() => {
                      setIsSearching(true);
                      setPayload({
                        status: "COMPLETED",
                        page: 0,
                        size: 50,
                        mobile: "",
                        resellerMobile: "",
                        isCreditOrder: null,
                      });
                    }}
                  >
                    Reset All
                  </Button>
                </Stack>
                <Stack
                  direction={{ xs: "column", sm: "column", md: "row" }}
                  spacing={2}
                  alignItems="center"
                >
                  <FormControl>
                    <InputLabel id="demo-simple-select-label">Size</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      size="small"
                      value={payload.size}
                      label="Size"
                      onChange={(e) => {
                        setPayload({
                          ...payload,
                          page: 0,
                          size: e.target.value,
                        });
                      }}
                      sx={{
                        fontSize: {
                          xs: "12px",
                          sm: "13x",
                          md: "13px",
                          lg: "16px",
                        },
                      }}
                    >
                      <MenuItem
                        value={10}
                        sx={{
                          fontSize: {
                            xs: "12px",
                            sm: "13x",
                            md: "13px",
                            lg: "13px",
                          },
                        }}
                      >
                        10
                      </MenuItem>
                      <MenuItem
                        value={25}
                        sx={{
                          fontSize: {
                            xs: "12px",
                            sm: "13x",
                            md: "13px",
                            lg: "13px",
                          },
                        }}
                      >
                        25
                      </MenuItem>
                      <MenuItem
                        value={50}
                        sx={{
                          fontSize: {
                            xs: "12px",
                            sm: "13x",
                            md: "13px",
                            lg: "13px",
                          },
                        }}
                      >
                        50
                      </MenuItem>
                      <MenuItem
                        value={100}
                        sx={{
                          fontSize: {
                            xs: "12px",
                            sm: "13x",
                            md: "13px",
                            lg: "13px",
                          },
                        }}
                      >
                        100
                      </MenuItem>
                    </Select>
                  </FormControl>
                  <Pagination
                    count={totalPages}
                    page={payload.page + 1}
                    color="primary"
                    onChange={handlePageChange}
                    siblingCount={0}
                    boundaryCount={isMobile ? 1 : 2}
                  />
                </Stack>
              </Stack>
            </div>
          </div>
        </div>
      </div>
      <div
        className={
          "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded " +
          (color === "light" ? "bg-white" : "bg-lightBlue-900 text-white")
        }
      >
        <div className="block w-full overflow-x-auto lg:p-8 py-3 px-5">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-100">
              <tr className="divide-x divide-gray-200 ">
                <TableHeader heading="S no." />
                <TableHeader heading="Image" />
                <TableHeader heading="User " />
                <TableHeader heading="Reseller" />
                <TableHeader heading="Status" />
                <TableHeader heading="Order Value" />
                <TableHeader heading="remark" />
                <TableHeader heading="Date" />
                <TableHeader heading="Invoice" />
                <TableHeader heading="" />
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white">
              {orders.map((item, i) => (
                <OrderRow
                  key={payload.size * payload.page + i}
                  handleGenerateInvoice={handleGenerateInvoice}
                  order={item}
                  i={payload.size * payload.page + i}
                  setEditOrderOpen={setEditOrderOpen}
                  setEditPayload={setEditPayload}
                />
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <Dialog open={openGenerateInvoice} onClose={handleCloseGenerateInvoice}>
        <DialogTitle>Generate Invoice</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            id="invoiceNo"
            label="Enter Invoice Number"
            type="text"
            value={invoice.id}
            onChange={(e) => {
              setInvoice({ ...invoice, invoiceNo: e.target.value });
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={generateInvoice}>
            {generateLoad ? "Generating....." : "Generate"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

const OrderRow = ({
  order,
  i,
  handleGenerateInvoice,
  setEditOrderOpen,
  setEditPayload,
}) => {
  return (
    <>
      <tr key={i} className="divide-x divide-gray-200 hover:bg-slate-100">
        <TableElement value={i + 1} />
        <TableElement
          value={
            order?.imageUrl ? (
              <a href={order.imageUrl} target="__blank">
                <img
                  src={order?.imageUrl}
                  alt=""
                  height="100px"
                  className="w-20 bg-white border-4 hover:scale-[2]"
                />
              </a>
            ) : (
              <>-</>
            )
          }
        />

        <TableElement
          value={
            <span className="font-semibold">
              {order?.user?.name} <br />
              {order?.user?.mobile} <br />
              {order?.tnxId}
            </span>
          }
          type="element"
        />
        <TableElement
          value={
            order?.reseller !== null ? (
              <>
                {order?.reseller?.name} <br />
                {order?.reseller?.mobile} <br />{" "}
              </>
            ) : (
              ""
            )
          }
        />
        <TableElement value={order?.status} />
        <TableElement
          value={
            <>
              <span className="font-semibold inline-block">
                {`( ${order.key} + ${order.schemeKey || 0} ) = ${
                  order.key + order.schemeKey || 0
                }`}
              </span>
              <br />
              <br />
              <span className="font-semibold">{` ${
                order.key + order.schemeKey || 0
              } * ${order.price} = ${order.amount}`}</span>
            </>
          }
        />
        <TableElement value={order?.remark || "-"} />

        <TableElement value={formatDateTimeComponent(order?.createdOn)} />

        <TableElement
          value={
            order?.invoiceUrl ? (
              <a href={order.invoiceUrl} target="__blank">
                <Button variant="text" aria-describedby="showInvoice-popover">
                  Show
                </Button>
              </a>
            ) : (
              <Button
                variant="text"
                onClick={() => handleGenerateInvoice(order?.id)}
                aria-describedby="url-popover"
              >
                Generate
              </Button>
            )
          }
        />
        <TableElement
          value={
            <Button
              onClick={() => {
                setEditOrderOpen(true);
                setEditPayload(order);
              }}
            >
              Edit
            </Button>
          }
        />
      </tr>
    </>
  );
};

const EditDialog = ({
  editPayload,
  setEditPayload,
  setEditOrderOpen,
  editOrderOpen,
  handleUpdateOrder,
}) => {
  return (
    <Dialog
      fullWidth
      maxWidth="lg"
      open={editOrderOpen}
      onClose={() => {
        setEditOrderOpen(false);
      }}
    >
      <DialogTitle>
        <div className=" relative flex justify-center w-full font-bold">
          <span>edit order </span>
          <span
            className="absolute top-0 right-0 text-red-500 cursor-pointer"
            variant="text"
            onClick={() => setEditOrderOpen(false)}
          >
            close
          </span>
        </div>
      </DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <TextField
              sx={{ marginY: 2 }}
              label="Name"
              fullWidth
              name="name"
              variant="standard"
              contentEditable={false}
              value={editPayload?.user?.name}
              // onChange={handleChange}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <TextField
              sx={{ marginY: 2 }}
              label="Mobile"
              fullWidth
              name="name"
              variant="standard"
              contentEditable={false}
              value={editPayload?.user?.mobile}
              // onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} md={4}></Grid>
          <Grid item xs={12} md={4}>
            <TextField
              sx={{ marginY: 2 }}
              label=" Reseller Name"
              fullWidth
              name="name"
              variant="standard"
              contentEditable={false}
              value={editPayload?.reseller?.name || "-"}
              // onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              sx={{ marginY: 2 }}
              label=" Reseller Mobile"
              fullWidth
              name="name"
              variant="standard"
              contentEditable={false}
              value={editPayload?.reseller?.mobile || "-"}
              // onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} md={4}></Grid>
          <Grid item xs={12} md={4}>
            <TextField
              sx={{ marginY: 2 }}
              label=" Keys"
              fullWidth
              variant="standard"
              contentEditable={false}
              value={"" + editPayload.key}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              sx={{ marginY: 2 }}
              label="Scheme Keys"
              fullWidth
              variant="standard"
              contentEditable={false}
              value={"" + editPayload?.schemeKey}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              sx={{ marginY: 2 }}
              label="Price"
              fullWidth
              variant="standard"
              contentEditable={false}
              value={"" + editPayload?.price}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              sx={{ marginY: 2 }}
              label="Amount"
              fullWidth
              variant="standard"
              contentEditable={false}
              value={"" + editPayload?.amount}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              sx={{ marginY: 2 }}
              label=" Remark "
              fullWidth
              variant="standard"
              value={editPayload?.remark}
              onChange={(e) => {
                setEditPayload((prev) => ({
                  ...prev,
                  remark: e.target.value,
                }));
              }}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <FormControl sx={{ marginY: 2 }}>
              <FormLabel id="demo-controlled-radio-buttons-group">
                Credit Order
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group"
                value={editPayload?.isCreditOrder}
                onChange={(e) => {
                  setEditPayload((prev) => ({
                    ...prev,
                    isCreditOrder: e.target.value,
                  }));
                }}
              >
                <FormControlLabel
                  value={true}
                  control={<Radio />}
                  label="True"
                />
                <FormControlLabel
                  value={false}
                  control={<Radio />}
                  label="False"
                />
              </RadioGroup>
            </FormControl>
            <FormControl sx={{ marginY: 2 }}>
              <FormLabel id="demo-controlled-radio-buttons-group">
                Deleted
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group"
                value={editPayload?.isDeleted}
                onChange={(e) => {
                  setEditPayload((prev) => ({
                    ...prev,
                    isDeleted: e.target.value,
                  }));
                }}
              >
                <FormControlLabel
                  value={true}
                  control={<Radio />}
                  label="True"
                />
                <FormControlLabel
                  value={false}
                  control={<Radio />}
                  label="False"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={async () => {
            await handleUpdateOrder();
            setEditOrderOpen(false);
          }}
        >
          Save Changes
        </Button>
      </DialogActions>
    </Dialog>
  );
};
