import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import TableHeader from "components/Table/TableHeader";
import TableElement from "components/Table/TableElement";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from "@mui/material";
import { getEnterprises } from "apis/enterprises";
import { formatDateTimeComponent } from "utils";
import { createEnterprise } from "apis/enterprises";

export default function Enterprises() {
  const color = "light";

  const [enterprise, setEnterprise] = useState([]);

  const [payload, setPayload] = useState({});

  const [open, setOpen] = useState(false);

  const handleGetEnterprises = async () => {
    try {
      const { data } = await getEnterprises();

      setEnterprise(data.data);
    } catch (err) {
      toast(err.response?.status + " " + err?.response?.data?.message, {
        type: "error",
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1000,
      });
    }
  };

  const handleCreateEnterprises = async () => {
    try {
      const { data } = await createEnterprise(payload);

      window.open(data.data.url, "_blank").focus();

      setOpen(false);
    } catch (err) {
      toast(err.response?.status + " " + err?.response?.data?.message, {
        type: "error",
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1000,
      });
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setPayload({
      ...payload,
      [name]: value,
    });
  };

  useEffect(() => {
    handleGetEnterprises();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
        }}>
        <DialogTitle>Enter Details for Enterprise</DialogTitle>
        <DialogContent>
          <Grid container>
            <Grid xs={12} md={6}>
              <TextField
                id="outlined-basic"
                sx={{ m: 2 }}
                label="Email"
                name="email"
                variant="outlined"
                value={payload.email}
                onChange={handleChange}
              />
            </Grid>
            <Grid xs={12} md={6}>
              <TextField
                id="outlined-basic"
                type="password"
                label="Password"
                name="password"
                variant="outlined"
                sx={{ m: 2 }}
                value={payload.password}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={async () => {
              await handleCreateEnterprises();
            }}>
            Create
          </Button>
        </DialogActions>
      </Dialog>

      <div
        className={
          "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded " +
          (color === "light" ? "bg-white" : "bg-lightBlue-900 text-white")
        }>
        <div
          className={
            "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded " +
            (color === "light" ? "bg-white" : "bg-lightBlue-900 text-white")
          }>
          <div className="flex justify-evenly my-4">
            <Button
              type="submit"
              variant="contained"
              onClick={() => {
                setOpen(true);
              }}>
              Create NEW
            </Button>
          </div>
        </div>{" "}
        <div className="block w-full overflow-x-auto p-8">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-100">
              <tr className="divide-x divide-gray-200 ">
                <TableHeader heading="S No." />
                <TableHeader heading="Name" />
                <TableHeader heading="Email" />
                <TableHeader heading="Device Count" />
                <TableHeader heading="Token" />
                <TableHeader heading="Active" />
                <TableHeader heading="Deleted" />
                <TableHeader heading="Created On" />
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white">
              {enterprise.map((e, i) => {
                return (
                  <tr
                    key={i}
                    className="divide-x divide-gray-200 hover:bg-slate-100">
                    <TableElement value={i + 1} />
                    <TableElement
                      value={<span className="font-semibold">{e?.name}</span>}
                      type="element"
                    />
                    <TableElement
                      value={<span className="font-semibold">{e?.email}</span>}
                      type="element"
                    />
                    <TableElement
                      value={
                        <span className="font-semibold">{e?.deviceCount}</span>
                      }
                      type="element"
                    />
                    <TableElement
                      value={
                        <span className="font-semibold">
                          {e.enrollmentToken
                            ? e?.enrollmentToken?.slice(
                                0,
                                e.enrollmentToken.length - 10
                              ) + "**********"
                            : "-"}
                        </span>
                      }
                      type="element"
                    />
                    <TableElement
                      value={
                        e?.isActive ? (
                          <p className="text-green-500">YES</p>
                        ) : (
                          <p className="text-red-500">NO</p>
                        )
                      }
                    />
                    <TableElement
                      value={
                        e?.isDeleted ? (
                          <p className="text-red-500">YES</p>
                        ) : (
                          <p className="text-green-500">NO</p>
                        )
                      }
                    />
                    <TableElement
                      value={
                        <span className="font-semibold">
                          {formatDateTimeComponent(e?.createdOn)}
                        </span>
                      }
                      type="element"
                    />
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}
