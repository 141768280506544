import React, { useEffect, useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';

const style = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100vw',
    height: '100vh',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    position: 'absolute',
    zIndex: '50',
};

const CircularIndeterminateModal = ({ open }) => {
    const [visible, setVisible] = useState(open);

    useEffect(() => {
        if (open) {
            setVisible(true);
        } else {
            const timer = setTimeout(() => setVisible(false), 300);
            return () => clearTimeout(timer);
        }
    }, [open]);

    return (
        <Modal
            open={visible}
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backdropFilter: 'blur(1.1px)',
            }}
        >
            <Box sx={style}>
                <CircularProgress size={40} sx={{ color: '#3498db' }} />
            </Box>
        </Modal>
    );
};

export default CircularIndeterminateModal;
