import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Unstable_Grid2";
import TextField from "@mui/material/TextField";
import CloseIcon from "@mui/icons-material/Close";
import {
  Dialog,
  Divider,
  Pagination,
  Stack,
  useMediaQuery,
} from "@mui/material";
import TableElement from "components/Table/TableElement";
import TableHeader from "components/Table/TableHeader";
import { IssueApi } from "apis/Issues";
import { uploadFile } from "apis/master";
import { useNavigate } from "react-router";
import CircularIndeterminateModal from "../../utils/Loading";
import { useDispatch, useSelector } from "react-redux";
import { setLoadingPanel } from "../../redux/features/loading";
import { formatDateTimeComponent } from "utils";

export default function Issues() {
  const color = "light";
  const isLoading = useSelector((state) => state.loadingReducer.isLoading);
  const dispatch = useDispatch();
  const [issueDialog, setDialogIssue] = useState(false);
  const [isssueData, setIssueData] = useState([]);
  const [totalElements, setTotalElement] = useState(0);
  const [deleteModal, setDeleteModal] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const [imagePreview, setImagePreview] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [payload, setPayload] = useState({
    imei1: "",
    imei2: "",
    imageUrl: "",
    description: "",
  });
  const [mobile, setMobile] = useState("");
  const isMobile = useMediaQuery("(max-width:600px)");
  const navigate = useNavigate();
  const [DeleteId, setDeleteId] = useState("");
  const [EditId, setEditId] = useState("");

  const EditSubmit = async () => {
    try {
      await IssueApi.EditIssueById(EditId, payload);
      setPayload({
        imei1: "",
        imei2: "",
        imageUrl: "",
        description: "",
      });
      setDialogIssue(false);
      fetchDataIssue();
      setEditId("");
    } catch (err) {
      if (err?.response?.data) {
        toast(err.response.status + " " + err?.response?.data?.message, {
          type: "error",
          position: toast.POSITION.TOP_CENTER,
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            maxWidth: "90vw",
            width: "auto",
            margin: "5px  auto",
            padding: "5px 5px",
            fontSize: "14px",
          },
          closeButton: (
            <div className="flex justify-end">
              <button className="text-gray-500 hover:text-gray-800 focus:outline">
                <CloseIcon className="w-7 h-7" />
              </button>
            </div>
          ),
        });
      } else {
        toast(err?.response?.data?.message, {
          type: "error",
          position: toast.POSITION.TOP_CENTER,
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            maxWidth: "90vw",
            width: "auto",
            margin: "5px  auto",
            padding: "5px 5px",
            fontSize: "14px",
          },
          closeButton: (
            <div className="flex justify-end">
              <button className="text-gray-500 hover:text-gray-800 focus:outline">
                <CloseIcon className="w-7 h-7" />
              </button>
            </div>
          ),
        });
      }
    }
  };

  const handleEdit = async (id) => {
    setIsEdit(true);
    try {
      const { data } = await IssueApi.GetIssueById(id);
      setDialogIssue(true);
      setPayload({
        imei1: data.data.imei1,
        imei2: data.data.imei2,
        imageUrl: data.data.imageUrl,
        description: data.data.description,
      });
      setImagePreview(data.data.imageUrl);
      setMobile(data.data.user.mobile);
      setEditId(id);
    } catch (err) {
      if (err?.response?.data) {
        toast(err.response.status + " " + err?.response?.data?.message, {
          type: "error",
          position: toast.POSITION.TOP_CENTER,
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            maxWidth: "90vw",
            width: "auto",
            margin: "5px  auto",
            padding: "5px 5px",
            fontSize: "14px",
          },
          closeButton: (
            <div className="flex justify-end">
              <button className="text-gray-500 hover:text-gray-800 focus:outline">
                <CloseIcon className="w-7 h-7" />
              </button>
            </div>
          ),
        });
      } else {
        toast(err?.response?.data?.message, {
          type: "error",
          position: toast.POSITION.TOP_CENTER,
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            maxWidth: "90vw",
            width: "auto",
            margin: "5px  auto",
            padding: "5px 5px",
            fontSize: "14px",
          },
          closeButton: (
            <div className="flex justify-end">
              <button className="text-gray-500 hover:text-gray-800 focus:outline">
                <CloseIcon className="w-7 h-7" />
              </button>
            </div>
          ),
        });
      }
    }
  };

  const [pagePayload, setPagePayload] = useState({
    page: 0,
    size: 50,
  });

  const handleDescription = (e) => {
    const { value } = e.target;
    setPayload((prev) => ({
      ...prev,
      description: value,
    }));
  };

  const handleImeiTwo = (e) => {
    const { value } = e.target;
    setPayload((prev) => ({
      ...prev,
      imei2: value,
    }));
  };

  const handleImeiOne = (e) => {
    const { value } = e.target;
    setPayload((prev) => ({
      ...prev,
      imei1: value,
    }));
  };

  const fetchDataIssue = async () => {
    dispatch(setLoadingPanel(true));
    try {
      const { data } = await IssueApi.GetIssue();
      setIssueData(data.data);
      setTotalPages(Math.ceil(data.totalElements / pagePayload.size));
      setTotalElement(data.totalElements);
      dispatch(setLoadingPanel(false));
    } catch (err) {
      dispatch(setLoadingPanel(false));
      if (err?.response?.data) {
        toast(err.response.status + " " + err?.response?.data?.message, {
          type: "error",
          position: toast.POSITION.TOP_CENTER,
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            maxWidth: "90vw",
            width: "auto",
            margin: "5px  auto",
            padding: "5px 5px",
            fontSize: "14px",
          },
          closeButton: (
            <div className="flex justify-end">
              <button className="text-gray-500 hover:text-gray-800 focus:outline">
                <CloseIcon className="w-7 h-7" />
              </button>
            </div>
          ),
        });
      } else {
        toast(err?.response?.data?.message, {
          type: "error",
          position: toast.POSITION.TOP_CENTER,
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            maxWidth: "90vw",
            width: "auto",
            margin: "5px  auto",
            padding: "5px 5px",
            fontSize: "14px",
          },
          closeButton: (
            <div className="flex justify-end">
              <button className="text-gray-500 hover:text-gray-800 focus:outline">
                <CloseIcon className="w-7 h-7" />
              </button>
            </div>
          ),
        });
      }
    }
  };

  useEffect(() => {
    fetchDataIssue();
  }, []);

  const handleCreateOrders = async () => {
    try {
      if (mobile.length === 10) {
        await IssueApi.CreateIssuesMobile(payload, mobile);
      } else {
        await IssueApi.CreateIssues(payload);
      }
      toast("Issue created successfully", {
        type: "success",
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        style: {
          maxWidth: "90vw",
          width: "auto",
          margin: "5px auto",
          padding: "5px 5px",
          fontSize: "14px",
        },
        closeButton: (
          <div className="flex justify-end">
            <button className="text-gray-500 hover:text-gray-800 focus:outline">
              <CloseIcon className="w-7 h-7" />
            </button>
          </div>
        ),
      });

      setPayload({
        imei1: "",
        imei2: "",
        imageUrl: "",
        description: "",
      });
      setDialogIssue(false);
      fetchDataIssue();
    } catch (err) {
      const errorMessage =
        err?.response?.data?.message || "An unexpected error occurred";
      const errorStatus = err?.response?.status || "Error";

      toast(`${errorStatus}: ${errorMessage}`, {
        type: "error",
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        style: {
          maxWidth: "90vw",
          width: "auto",
          margin: "5px auto",
          padding: "5px 5px",
          fontSize: "14px",
        },
        closeButton: (
          <div className="flex justify-end">
            <button className="text-gray-500 hover:text-gray-800 focus:outline">
              <CloseIcon className="w-7 h-7" />
            </button>
          </div>
        ),
      });
      if (err?.response?.status === 401) {
        localStorage.clear();
        navigate("/");
      }
    }
  };

  const handleImageChange = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    setImagePreview(URL.createObjectURL(file));

    let body = new FormData();
    body.append("document", file);

    try {
      const { data } = await uploadFile(body, "EXTRA");
      setPayload((prev) => ({ ...prev, imageUrl: data.data.url }));
    } catch (err) {
      toast(err.response?.status + " " + err?.response?.data?.message, {
        type: "error",
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        style: {
          maxWidth: "90vw",
          width: "auto",
          margin: "5px  auto",
          padding: "5px 5px",
          fontSize: "14px",
        },
        closeButton: (
          <div className="flex justify-end">
            <button className="text-gray-500 hover:text-gray-800 focus:outline">
              <CloseIcon className="w-7 h-7" />
            </button>
          </div>
        ),
      });
    }
  };

  const handlePageChange = (value) => {
    setPagePayload((prev) => ({
      ...prev,
      page: value - 1,
    }));
  };
  const handleDeleteIssue = async (id) => {
    try {
      await IssueApi.DeleteIssues(id);
      setDeleteModal(false);
      fetchDataIssue();
      setDeleteId("");
    } catch (err) {
      if (err?.response?.data) {
        toast(err.response.status + " " + err?.response?.data?.message, {
          type: "error",
          position: toast.POSITION.TOP_CENTER,
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            maxWidth: "90vw",
            width: "auto",
            margin: "5px  auto",
            padding: "5px 5px",
            fontSize: "14px",
          },
          closeButton: (
            <div className="flex justify-end">
              <button className="text-gray-500 hover:text-gray-800 focus:outline">
                <CloseIcon className="w-7 h-7" />
              </button>
            </div>
          ),
        });
      } else {
        toast(err?.response?.data?.message, {
          type: "error",
          position: toast.POSITION.TOP_CENTER,
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            maxWidth: "90vw",
            width: "auto",
            margin: "5px  auto",
            padding: "5px 5px",
            fontSize: "14px",
          },
          closeButton: (
            <div className="flex justify-end">
              <button className="text-gray-500 hover:text-gray-800 focus:outline">
                <CloseIcon className="w-7 h-7" />
              </button>
            </div>
          ),
        });
      }
    }
  };

  return (
    <>
      <CircularIndeterminateModal open={isLoading} />
      <div
        className={
          "relative flex min-w-0 break-words justify-center w-full py-6 shadow-lg rounded " +
          (color === "light" ? "bg-white" : "bg-lightBlue-900 text-white")
        }
      >
        <Button
          variant="contained"
          size="medium"
          color="info"
          sx={{
            fontSize: {
              xs: "12px",
              sm: "13x",
              md: "13px",
              lg: "13px",
            },
            padding: { md: "6px 16px", xs: "2px 8px" },
            width: "fit-content",
          }}
          onClick={() => {
            setIsEdit(false);
            setPayload({
              imei1: "",
              imei2: "",
              imageUrl: "",
              description: "",
            });
            setImagePreview(null);
            setMobile("");
            setDialogIssue(true);
          }}
        >
          Create Issue
        </Button>
      </div>

      {/* DIALOGS */}
      <div
        className={
          "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded " +
          (color === "light" ? "bg-white" : "bg-lightBlue-900 text-white")
        }
      >
        {/* ADD SERIES DIALOG */}
        <Dialog open={issueDialog} onClose={() => setDialogIssue(false)}>
          <DialogTitle>{isEdit ? "Edit Issue" : "Create Issue"}</DialogTitle>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  label={
                    <span className="text-xs sm:text-sm md:text-base lg:text-lg font-medium">
                      Imei1
                    </span>
                  }
                  variant="outlined"
                  fullWidth
                  inputProps={{
                    maxLength: 15,
                  }}
                  value={payload.imei1}
                  onChange={handleImeiOne}
                  InputProps={{
                    sx: {
                      fontSize: {
                        xs: "12px",
                        sm: "14px",
                        md: "16px",
                        lg: "16px",
                      },
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label={
                    <span className="text-xs sm:text-sm md:text-base lg:text-lg font-medium">
                      Imei2{" "}
                    </span>
                  }
                  variant="outlined"
                  fullWidth
                  inputProps={{
                    maxLength: 15,
                  }}
                  value={"" + payload.imei2}
                  InputProps={{
                    sx: {
                      fontSize: {
                        xs: "12px",
                        sm: "14px",
                        md: "16px",
                        lg: "16px",
                      },
                    },
                  }}
                  onChange={handleImeiTwo}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label={
                    <span className="text-xs sm:text-sm md:text-base lg:text-lg font-medium">
                      Description
                    </span>
                  }
                  variant="outlined"
                  multiline
                  rows={6}
                  fullWidth
                  value={"" + payload.description}
                  onChange={handleDescription}
                  InputProps={{
                    sx: {
                      fontSize: {
                        xs: "12px",
                        sm: "14px",
                        md: "16px",
                        lg: "16px",
                      },
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label={
                    <span className="text-xs sm:text-sm md:text-base lg:text-lg font-medium">
                      Mobile Number
                    </span>
                  }
                  variant="outlined"
                  fullWidth
                  inputProps={{
                    maxLength: 10,
                  }}
                  value={mobile}
                  onChange={(e) => setMobile(e.target.value)}
                  InputProps={{
                    sx: {
                      fontSize: {
                        xs: "12px",
                        sm: "14px",
                        md: "16px",
                        lg: "16px",
                      },
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <input
                  accept="image/*"
                  id="payment-screenshot"
                  type="file"
                  style={{ display: "none" }}
                  onChange={handleImageChange}
                />
                <label htmlFor="payment-screenshot">
                  <Button
                    variant="contained"
                    component="span"
                    sx={{
                      fontSize: {
                        xs: "12px",
                        sm: "13x",
                        md: "13px",
                        lg: "13px",
                      },
                      padding: { md: "6px 16px", xs: "2px 8px" },
                    }}
                  >
                    Upload Image
                  </Button>
                </label>
                {imagePreview && (
                  <img
                    src={imagePreview}
                    alt="Payment Screenshot Preview"
                    style={{ marginTop: 10, maxHeight: 100 }}
                  />
                )}
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              color="primary"
              sx={{
                fontSize: {
                  xs: "12px",
                  sm: "13x",
                  md: "13px",
                  lg: "13px",
                },
                padding: { md: "6px 16px", xs: "2px 8px" },
              }}
              onClick={isEdit ? EditSubmit : handleCreateOrders}
            >
              {isEdit ? "Edit" : "Create"}
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog open={deleteModal} onClose={() => setDeleteModal(false)}>
          <DialogContent>
            <h5>Do you want to delete?</h5>
          </DialogContent>
          <DialogActions className="flex justify-between gap-4 border-t border-gray-200 pt-4">
            <Button
              variant="text"
              color="primary"
              className="text-sm md:text-base px-4 w-full py-2 rounded-md hover:bg-gray-100 transition-colors"
              onClick={() => setDeleteModal(false)}
            >
              Cancel
            </Button>
            <Button
              variant="text"
              color="primary"
              sx={{
                color: "red",
              }}
              className="text-sm md:text-base px-4 py-2 w-full rounded-md hover:bg-red-100 transition-colors text-red-600"
              onClick={() => handleDeleteIssue(DeleteId)}
            >
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      <div
        className={
          "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded " +
          (color === "light" ? "bg-white" : "bg-lightBlue-900 text-white")
        }
      >
        <div className="text-center flex xl:flex-row flex-col gap-3 justify-between p-2 md:p-6">
          <div className="my-1">
            <Stack
              direction={{ xs: "column", md: "row" }}
              spacing={2}
              alignItems="center"
              justifyContent="center"
              divider={<Divider orientation="vertical" flexItem />}
            >
              <div className="flex items-center">
                <span className="text-sm font-extrabold align-baseline">
                  Total :- {totalElements}
                </span>
              </div>
              <Pagination
                count={totalPages}
                page={pagePayload.page + 1}
                color="primary"
                onChange={handlePageChange}
                siblingCount={0}
                boundaryCount={isMobile ? 1 : 2}
              />
            </Stack>
          </div>
        </div>
        <div className="block w-full overflow-x-auto p-4 md:p-8">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-100">
              <tr className="divide-x divide-gray-200">
                <TableHeader heading="S No." />
                <TableHeader heading="Image" />
                <TableHeader heading="User" />
                <TableHeader heading="IMEIS" />
                <TableHeader heading="Description" />
                <TableHeader heading="Created on" />
                <TableHeader heading="Updated on" />
                <TableHeader heading="" />
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white">
              {isssueData.map((device, i) => (
                <tr
                  key={i}
                  className="divide-x divide-gray-200 hover:bg-slate-100"
                >
                  <TableElement value={i + 1} />
                  <TableElement
                    value={
                      <img
                        src={device?.imageUrl}
                        height="100px"
                        className="w-20 bg-white border-4 hover:scale-[2]"
                        alt="issue-preview"
                      />
                    }
                    type="element"
                  />
                  <TableElement
                    value={
                      <span className="font-semibold">
                        {device?.user?.name} <br />
                        {device?.user?.mobile} <br />
                      </span>
                    }
                    type="element"
                  />
                  <TableElement
                    value={
                      <span>
                        {device?.imei1}
                        <br />
                        {device.imei2}
                      </span>
                    }
                    type="element"
                  />

                  <TableElement
                    value={
                      <span className="font-semibold">
                        {device?.description}
                      </span>
                    }
                    type="element"
                  />
                  <TableElement
                    value={
                      <span>{formatDateTimeComponent(device?.createdOn)}</span>
                    }
                  />
                  <TableElement
                    value={
                      <span>
                        {formatDateTimeComponent(device?.lastUpdatedOn)}
                      </span>
                    }
                  />
                  <TableElement
                    value={
                      <div className="flex flex-col gap-2">
                        <Button
                          variant="text"
                          onClick={() => handleEdit(device?.id)}
                          aria-describedby="url-popover"
                        >
                          Edit
                        </Button>
                        <Button
                          variant="text"
                          onClick={() => {
                            setDeleteId(device?.id);
                            setDeleteModal(true);
                          }}
                          sx={{ color: "red" }}
                          aria-describedby="url-popover"
                        >
                          Delete
                        </Button>
                      </div>
                    }
                    type="element"
                  />
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}
