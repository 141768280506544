import axios from "axios";
import { EMI_BOOK } from "./constants";
import { getHeaders } from "./head";

export const createEnterprise = (payload) => {
  return axios.post(`${EMI_BOOK}/v2/enterprise`, payload, {
    headers: getHeaders(true),
  });
};
export function getEnterprises() {
  return axios.get(`${EMI_BOOK}/v2/enterprise`, {
    headers: getHeaders(true),
  });
}
