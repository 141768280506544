import React from "react";
import { Link } from "react-router-dom";
// import NotificationDropdown from "../../components/Dropdowns/NotificationDropdown.js";
import UserDropdown from "../../components/Dropdowns/UserDropdown.js";

const navigationItems = [
  {
    title: "Users",
    path: "/admin/users",
    icon: "fas fa-tools",
  },
  {
    title: "Keys",
    path: "/admin/keys",
    icon: "fas fa-table",
  },
  {
    title: "All Orders",
    path: "/admin/orders",
    icon: "fas fa-tv",
  },
  {
    title: "Create Orders",
    path: "/admin/createorders",
    icon: "fas fa-tv",
  },
  {
    title: "Promotions",
    path: "/admin/promotions",
    icon: "fas fa-barcode",
  },
  {
    title: "Add Promotions",
    path: "/admin/addpromotions",
    icon: "fas fa-chart-simple",
  },
  {
    title: "Customer Registrations",
    path: "/admin/customerregistration",
    icon: "fas fa-chart-simple",
  },
  {
    title: "Create Notifications",
    path: "/admin/createnotifications",
    icon: "fas fa-chart-simple",
  },
  {
    title: "Issues",
    path: "/admin/issue",
    icon: "fas fa-chart-simple",
  },
  {
    title: "TOTP",
    path: "/admin/totp",
    icon: "fas fa-lock",
  },
  {
    title: "Devices",
    path: "/admin/devices",
    icon: "fas fa-chart-simple",
  },
  {
    title: "LogOut",
    path: "/",
    icon: "fas fa-chart-simple",
  },
];

export default function Sidebar() {
  const [collapseShow, setCollapseShow] = React.useState("hidden");
  const [active, setActive] = React.useState("");

  const NavItem = ({ item }) => {
    const isActive =
      active === item.title || window.location.href.indexOf(item.path) !== -1;
    return (
      <li className="items-center">
        <Link
          className={`text-xs uppercase py-3 font-bold block ${
            isActive
              ? "text-lightBlue-500 hover:text-lightBlue-600"
              : "text-blueGray-700 hover:text-blueGray-500"
          }`}
          onClick={() => {
            setActive(item.title);
            setCollapseShow("hidden");
            if (item.title === "LogOut") localStorage.removeItem("token");
          }}
          to={item.path}
        >
          <i
            className={`${item.icon} mr-2 text-sm ${
              isActive ? "opacity-75" : "text-blueGray-300"
            }`}
          ></i>{" "}
          {item.title}
        </Link>
      </li>
    );
  };

  return (
    <>
      {collapseShow !== "hidden" && (
        <div
          className="fixed inset-0 bg-black/30 backdrop-blur-sm z-30 transition-opacity md:hidden"
          onClick={() => setCollapseShow("hidden")}
        />
      )}
      <nav
        className={`
        fixed top-0 left-0 bottom-0 flex flex-col w-80 
        bg-white shadow-xl z-40 transition-transform duration-300 ease-in-out
        ${collapseShow === "hidden" ? "-translate-x-full" : "translate-x-0"}
        md:translate-x-0 md:w-64 md:z-10
      `}
      >
        <div className="flex-1 flex flex-col min-h-0">
          <div className="flex items-center justify-between px-2 sm:px-6 h-16 border-b border-gray-200">
            <Link
              className="md:block text-left md:pb-2 text-blueGray-600 mr-0 inline-block whitespace-nowrap text-sm uppercase font-bold p-4 px-0"
              to="/admin/users"
            >
              EMI BOOK ADMIN
            </Link>
            <button
              className="md:hidden text-gray-500 hover:text-gray-700"
              onClick={() => setCollapseShow("hidden")}
            >
              <i className="fas fa-times text-xl"></i>
            </button>
          </div>
          <div className="flex-1 px-6 py-4 overflow-y-auto  whitespace-nowrap h-full">
            <ul className="space-y-1">
              {navigationItems.map((item, index) => (
                <NavItem key={index} item={item} />
              ))}
            </ul>
          </div>
        </div>
      </nav>
      <div className="flex items-center justify-between px-6 h-16 md:h-0 border-b border-gray-200">
        <div className="flex flex-row-reverse gap-2">
          <Link
            className="md:block text-left md:pb-2 text-blueGray-600 mr-0 inline-block whitespace-nowrap text-sm uppercase font-bold p-4 px-0"
            to="/admin/users"
          >
            EMI BOOK ADMIN
          </Link>
          <button
            className="top-4 left-4 md:hidden p-2 rounded-md"
            onClick={() => setCollapseShow("visible")}
          >
            <i className="fas fa-bars text-xl text-gray-600"></i>
          </button>
        </div>
        <div className="flex gap-2">
          <div className="flex justify-end">
            <ul className="md:hidden items-center flex flex-wrap list-none">
              <li className="inline-block relative">
                <UserDropdown />
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}
