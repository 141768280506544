import React from "react";

const TableHeader = ({ heading }) => {
  return (
    <th
      scope="col"
      className="xl:px-4 md:px-5 px-7 py-3.5 whitespace-nowrap text-xs xl:text-sm font-normal text-gray-500 text-center"
    >
      <span className="font-bold uppercase text-xs xl:text-sm"> {heading}</span>
    </th>
  );
};

export default TableHeader;
