import React, { useState } from "react";
import { toast } from "react-toastify";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Unstable_Grid2";
import TextField from "@mui/material/TextField";
import { createOrderByMobile } from "apis/orders";
import { uploadFile } from "apis/master";
import CloseIcon from "@mui/icons-material/Close";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";

export default function CreateOrders() {
  const color = "light";
  const [imagePreview, setImagePreview] = useState(null);
  const [payload, setPayload] = useState({
    mobile: "",
    imageUrl: "",
    key: 0,
    schemeKey: 0,
    amount: 0,
    price: 0,
    isCreditOrder: false,
  });

  const handleMobileChange = (e) => {
    const { value } = e.target;
    const mob = Number(value);
    setPayload((prev) => ({
      ...prev,
      mobile: mob,
    }));
  };

  const handlePriceChange = (e) => {
    const { value } = e.target;
    const pri = Number(value);
    setPayload((prev) => ({
      ...prev,
      price: pri,
    }));
  };

  const handleKeyChange = (e) => {
    const { value } = e.target;
    const key = Number(value);
    setPayload((prev) => ({
      ...prev,
      key,
      amount: key * prev.price,
    }));
  };

  const handleSchemeChange = (e) => {
    const { value } = e.target;
    const scheme = Number(value);
    setPayload((prev) => ({
      ...prev,
      schemeKey: scheme,
    }));
  };

  const handleAmountChange = (e) => {
    const { value } = e.target;
    const amount = Number(value);
    setPayload((prev) => ({
      ...prev,
      amount,
      key: Math.floor(amount / prev.price),
    }));
  };

  const handleCreateOrders = () => {
    createOrderByMobile(payload.mobile, payload)
      .then((res) => {
        toast("Order created successfully", {
          type: "success",
          position: toast.POSITION.TOP_CENTER,
          autoClose: 1000,
        });
        setPayload({
          mobile: "",
          imageUrl: "",
          key: 0,
          schemeKey: 0,
          amount: 0,
          price: 175,
          isCreditOrder: false,
        });
        setImagePreview(null);
      })
      .catch((err) => {
        if (err?.response?.data) {
          toast(err.response.status + " " + err?.response?.data?.message, {
            type: "error",
            position: toast.POSITION.TOP_CENTER,
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            style: {
              maxWidth: "90vw",
              width: "auto",
              margin: "5px  auto",
              padding: "5px 5px",
              fontSize: "14px",
            },
            closeButton: (
              <div className="flex justify-end">
                <button className="text-gray-500 hover:text-gray-800 focus:outline">
                  <CloseIcon className="w-7 h-7" />
                </button>
              </div>
            ),
          });
        } else {
          toast(err?.response?.data?.message, {
            type: "error",
            position: toast.POSITION.TOP_CENTER,
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            style: {
              maxWidth: "90vw",
              width: "auto",
              margin: "5px  auto",
              padding: "5px 5px",
              fontSize: "14px",
            },
            closeButton: (
              <div className="flex justify-end">
                <button className="text-gray-500 hover:text-gray-800 focus:outline">
                  <CloseIcon className="w-7 h-7" />
                </button>
              </div>
            ),
          });
        }
        // if (err.response.status === 401) {
        //   localStorage.clear();
        //   navigate("/");
        // }
      });
  };

  // const handleImageChange = (event) => {
  //   const file = event.target.files[0];
  //   setPayload({
  //     ...payload,
  //     imageUrl: file,
  //   });
  //   setImagePreview(URL.createObjectURL(file));
  // };

  const handleImageChange = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    setImagePreview(URL.createObjectURL(file));

    let body = new FormData();
    body.append("document", file);

    try {
      const { data } = await uploadFile(body, "PAYMENT");
      setPayload((prev) => ({ ...prev, imageUrl: data.data.url }));
    } catch (err) {
      toast(err.response?.status + " " + err?.response?.data?.message, {
        type: "error",
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        style: {
          maxWidth: "90vw",
          width: "auto",
          margin: "5px  auto",
          padding: "5px 5px",
          fontSize: "14px",
        },
        closeButton: (
          <div className="flex justify-end">
            <button className="text-gray-500 hover:text-gray-800 focus:outline">
              <CloseIcon className="w-7 h-7" />
            </button>
          </div>
        ),
      });
    }
  };

  return (
    <>
      <div
        className={
          "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded " +
          (color === "light" ? "bg-white" : "bg-lightBlue-900 text-white")
        }
      >
        {/* ADD SERIES DIALOG */}
        <DialogTitle>Create Order</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                label={
                  <span className="text-xs sm:text-sm md:text-base lg:text-lg font-medium">
                    Mobile
                  </span>
                }
                variant="outlined"
                fullWidth
                value={payload.mobile}
                onChange={handleMobileChange}
                InputProps={{
                  sx: {
                    fontSize: {
                      xs: "12px",
                      sm: "14px",
                      md: "16px",
                      lg: "16px",
                    },
                  },
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label={
                  <span className="text-xs sm:text-sm md:text-base lg:text-lg font-medium">
                    Price{" "}
                  </span>
                }
                variant="outlined"
                fullWidth
                value={"" + payload.price}
                InputProps={{
                  sx: {
                    fontSize: {
                      xs: "12px",
                      sm: "14px",
                      md: "16px",
                      lg: "16px",
                    },
                  },
                }}
                onChange={handlePriceChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label={
                  <span className="text-xs sm:text-sm md:text-base lg:text-lg font-medium">
                    Keys Required{" "}
                  </span>
                }
                variant="outlined"
                fullWidth
                value={"" + payload.key}
                onChange={handleKeyChange}
                InputProps={{
                  sx: {
                    fontSize: {
                      xs: "12px",
                      sm: "14px",
                      md: "16px",
                      lg: "16px",
                    },
                  },
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label={
                  <span className="text-xs sm:text-sm md:text-base lg:text-lg font-medium">
                    Scheme{" "}
                  </span>
                }
                variant="outlined"
                fullWidth
                value={"" + payload.schemeKey}
                onChange={handleSchemeChange}
                InputProps={{
                  sx: {
                    fontSize: {
                      xs: "12px",
                      sm: "14px",
                      md: "16px",
                      lg: "16px",
                    },
                  },
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label={
                  <span className="text-xs sm:text-sm md:text-base lg:text-lg font-medium">
                    Amount
                  </span>
                }
                variant="outlined"
                fullWidth
                value={"" + payload.amount}
                onChange={handleAmountChange}
                InputProps={{
                  sx: {
                    fontSize: {
                      xs: "12px",
                      sm: "14px",
                      md: "16px",
                      lg: "16px",
                    },
                  },
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl sx={{ marginY: 2 }}>
                <FormLabel
                  id="demo-controlled-radio-buttons-group"
                  sx={{
                    fontSize: {
                      xs: "12px",
                      sm: "13x",
                      md: "13px",
                      lg: "16px",
                    },
                  }}
                >
                  Credit Order
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group"
                  name="isCreditOrder"
                  value={payload?.isCreditOrder}
                  onChange={(e) => {
                    setPayload((prev) => ({
                      ...prev,
                      isCreditOrder: e.target.value,
                    }));
                  }}
                >
                  <FormControlLabel
                    value={true}
                    control={
                      <Radio
                        sx={{
                          padding: "8px",
                          "& .MuiSvgIcon-root": {
                            fontSize: {
                              xs: "18px",
                              md: "22px",
                            },
                          },
                        }}
                      />
                    }
                    label={
                      <span className="text-xs sm:text-sm md:text-base lg:text-lg font-medium">
                        True{" "}
                      </span>
                    }
                  />
                  <FormControlLabel
                    value={false}
                    control={
                      <Radio
                        sx={{
                          padding: "8px",
                          "& .MuiSvgIcon-root": {
                            fontSize: {
                              xs: "18px",
                              md: "22px",
                            },
                          },
                        }}
                      />
                    }
                    label={
                      <span className="text-xs sm:text-sm md:text-base lg:text-lg font-medium">
                        False
                      </span>
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <input
                accept="image/*"
                id="payment-screenshot"
                type="file"
                style={{ display: "none" }}
                onChange={handleImageChange}
              />
              <label htmlFor="payment-screenshot">
                <Button
                  variant="contained"
                  component="span"
                  sx={{
                    fontSize: {
                      xs: "12px",
                      sm: "13x",
                      md: "13px",
                      lg: "13px",
                    },
                    padding: { md: "6px 16px", xs: "2px 8px" },
                  }}
                >
                  Upload Payment Screenshot
                </Button>
              </label>
              {imagePreview && (
                <img
                  src={imagePreview}
                  alt="Payment Screenshot Preview"
                  style={{ marginTop: 10, maxHeight: 100 }}
                />
              )}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            sx={{
              fontSize: {
                xs: "12px",
                sm: "13x",
                md: "13px",
                lg: "13px",
              },
              padding: { md: "6px 16px", xs: "2px 8px" },
            }}
            onClick={handleCreateOrders}
          >
            Create
          </Button>
        </DialogActions>
      </div>
    </>
  );
}
