import axios from "axios";

const Headers = {
  getHeaders: () => {
    return {
      "x-api-key":
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxOTU4NDk0NDY3NDY0MzE1NjQ5ODc0NTY0ODkyNiIsIm5hbWUiOiJTY2hlbWVzIEJvb29rIiwiaWF0IjoxNTE2MjM5MDIyfQ.uVUOR2U82nTjNccumsyggdScQh0DA5PdlwF_l0VUdfd",
    };
  },
};

const base_url = "https://api.schemesbook.com/";

export const TotpAPI = {
  GetTOTP: async () => {
    const response = await axios.post(base_url + "onboarding/otp/totp", {
      headers: Headers.getHeaders(),
    });
    return response;
  },
};
