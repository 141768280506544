import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    loggedIn: false,
    checking: false,
};

export const loggedIn = createSlice({
    name: 'loggedIn',
    initialState: initialState,
    reducers: {
        setLoggedIn: (state, action) => {
            state.loggedIn = true;
        },
        setChecking: (state, action) => {
            state.checking = action.payload;
        },
        setLoggedOut: (state, action) => {
            state.loggedIn = false;
        },
    },
});

export const { setLoggedIn, setLoggedOut, setChecking } = loggedIn.actions;

export default loggedIn.reducer;
