import React, { useState } from "react";
import { toast } from "react-toastify";
import Button from "@mui/material/Button";

import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Unstable_Grid2";

import { createPromotions } from "apis/promotions";
import { uploadFile } from "apis/master";
export default function AddPromotions() {
  const color = "light";
  const [imagePreview, setImagePreview] = useState(null);
  const [payload, setPayload] = useState({
    url: "",
    displayOrder: 0,
    isActive: true,
    isDeleted: false,
    type: "IMAGE",
  });

  const handleUploadPromotions = () => {
    createPromotions(payload)
      .then((res) => {
        toast("Promotion Uploaded successfully", {
          type: "success",
          position: toast.POSITION.TOP_CENTER,
          autoClose: 1000,
        });
        setPayload({
          url: "",
          displayOrder: 0,
          isActive: true,
          isDeleted: false,
          type: "IMAGE",
        });
        setImagePreview(null);
      })
      .catch((err) => {
        if (err?.response?.data) {
          toast(err.response.status + " " + err?.response?.data?.message, {
            type: "error",
            position: toast.POSITION.TOP_CENTER,
            autoClose: 1000,
          });
        } else {
          toast(err?.response?.data?.message, {
            type: "error",
            position: toast.POSITION.TOP_CENTER,
            autoClose: 1000,
          });
        }
        // if (err.response.status === 401) {
        //   localStorage.clear();
        //   navigate("/");
        // }
      });
  };

  const handleImageChange = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    setImagePreview(URL.createObjectURL(file));

    let body = new FormData();
    body.append("document", file);

    try {
      const { data } = await uploadFile(body, "PAYMENT");
      setPayload((prev) => ({ ...prev, url: data.data.url }));
    } catch (err) {
      toast(err.response?.status + " " + err?.response?.data?.message, {
        type: "error",
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1000,
      });
    }
  };

  return (
    <>
      <div
        className={
          "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded " +
          (color === "light" ? "bg-white" : "bg-lightBlue-900 text-white")
        }
      >
        {/* ADD SERIES DIALOG */}
        <DialogTitle>Upload Promotion</DialogTitle>
        <DialogContent>
          <Grid container>
            <Grid xs={6} md={6}>
              <input
                type="file"
                accept="image/*"
                onChange={handleImageChange}
                style={{ margin: "16px" }}
              />
              {imagePreview && (
                <img
                  src={imagePreview}
                  alt="Selected"
                  style={{ margin: "16px", width: "100px", height: "100px" }}
                />
              )}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ paddingY: "18px" }}>
          <Button
            variant="contained"
            color="primary"
            sx={{
              fontSize: {
                xs: "12px",
                sm: "13x",
                md: "13px",
                lg: "16px",
              },
              padding: { md: "6px 16px", xs: "2px 8px" },
            }}
            onClick={handleUploadPromotions}
          >
            Create
          </Button>
        </DialogActions>
      </div>
    </>
  );
}
