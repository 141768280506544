import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import TableHeader from "components/Table/TableHeader";
import TableElement from "components/Table/TableElement";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Grid,
} from "@mui/material";
import {
  getDevices,
  deleteDevice,
  updatePolicy,
} from "apis/customerRegistration";
import { useDispatch, useSelector } from "react-redux";
import { setLoadingPanel } from "../../redux/features/loading";
import CircularIndeterminateModal from "../../utils/Loading";
import { getEnterprisesForDevices } from "apis/customerRegistration";

export default function Devices() {
  const color = "light";
  const [devices, setDevices] = useState([]);
  const [devicesFilter, setDevicesFilter] = useState(devices);
  const isLoading = useSelector((state) => state.loadingReducer.isLoading);
  const dispatch = useDispatch();
  const [pageToken, setPageToken] = useState(null);
  const [navigate, setNavigate] = useState({ next: null, prev: null });
  const [page, setPage] = useState(0);
  const [searchIme, setSearchIme] = useState("");
  const [enterprises, setEnterprises] = useState([]);
  const [selectedEnterprise, setSelectedEnterprise] = useState(
    "enterprises/LC00o85xc3"
  );

  // Delete Dialog States
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [enterpriseId, setEnterpriseId] = useState("");
  const [deviceId, setDeviceId] = useState("");
  const [confirmDeleteDialogOpen, setConfirmDeleteDialogOpen] = useState(false);

  // Update Policy Dialog States
  const [updatePolicyDialogOpen, setUpdatePolicyDialogOpen] = useState(false);
  const [policyName, setPolicyName] = useState("");

  const handleGetDevices = async () => {
    dispatch(setLoadingPanel(true));
    try {
      const { data } = await getDevices(selectedEnterprise, pageToken);
      setDevices(data.devices);
      setNavigate({ next: data.nextPageToken });
      setPage((prev) => (!pageToken ? 0 : prev + 1));
      dispatch(setLoadingPanel(false));
    } catch (err) {
      toast(err.response?.status + " " + err?.response?.data?.message, {
        type: "error",
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1000,
      });
      dispatch(setLoadingPanel(false));
    }
  };

  const handleDeleteDevice = async () => {
    try {
      dispatch(setLoadingPanel(true));
      await deleteDevice({ enterprise: enterpriseId, deviceId });
      toast("Device deleted successfully", {
        type: "success",
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1000,
      });
      setDeleteDialogOpen(false);
      handleGetDevices(); // Refresh the device list after deletion
    } catch (err) {
      toast(err.response?.status + " " + err?.response?.data?.message, {
        type: "error",
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1000,
      });
    } finally {
      dispatch(setLoadingPanel(false));
    }
  };

  const handleUpdatePolicy = async () => {
    try {
      dispatch(setLoadingPanel(true));
      await updatePolicy({
        enterprise: enterpriseId,
        deviceId,
        policy: policyName,
      });
      toast("Policy updated successfully", {
        type: "success",
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1000,
      });
      setUpdatePolicyDialogOpen(false);
      handleGetDevices();
    } catch (err) {
      toast(err.response?.status + " " + err?.response?.data?.message, {
        type: "error",
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1000,
      });
    } finally {
      dispatch(setLoadingPanel(false));
    }
  };

  const handleGetEnterprises = async () => {
    dispatch(setLoadingPanel(true));
    try {
      const { data } = await getEnterprisesForDevices();
      setEnterprises(data);
      dispatch(setLoadingPanel(false));
    } catch (err) {
      console.error(err);
      toast(
        `${err.response?.status} ${
          err?.response?.data?.message || "Error fetching data"
        }`,
        {
          type: "error",
          position: toast.POSITION.TOP_CENTER,
          autoClose: 1000,
        }
      );
      dispatch(setLoadingPanel(false));
    }
  };
  const handleChange = (event) => {
    const selectedName = enterprises.find(
      (enterprise) => enterprise.id === event.target.value
    )?.name;
    setSelectedEnterprise(selectedName || "");
  };
  useEffect(() => {
    handleGetEnterprises();
  }, []);
  useEffect(() => {
    handleGetDevices();
  }, [pageToken, selectedEnterprise]);

  useEffect(() => {
    if (searchIme) {
      const filteredDevices = devices.filter((device) =>
        device?.imei?.toString().includes(searchIme)
      );
      setDevicesFilter(filteredDevices);
    } else {
      setDevicesFilter(devices);
    }
  }, [searchIme, devices]);

  // const formatDate = (dateString) => {
  //   const date = new Date(dateString);
  //   return `${date.toLocaleDateString("en-GB", {
  //     day: "2-digit",
  //     month: "short",
  //     year: "numeric",
  //   })}, ${date.toLocaleTimeString("en-GB", {
  //     hour: "2-digit",
  //     minute: "2-digit",
  //     second: "2-digit",
  //     hour12: false,
  //   })}`;
  // };

  return (
    <>
      <CircularIndeterminateModal open={isLoading} />
      <div
        className={
          "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded " +
          (color === "light" ? "bg-white" : "bg-lightBlue-900 text-white")
        }
      >
        <div className="flex lg:flex-row flex-col gap-3 justify-evenly my-4 lg:px-8 md:px-6 px-3">
          <Button
            type="submit"
            disabled={!navigate.next}
            variant="contained"
            onClick={() => {
              if (navigate.next) {
                setPageToken(navigate.next);
              }
            }}
          >
            Next
          </Button>
          <Button
            type="button"
            variant="contained"
            color="secondary"
            onClick={() => setDeleteDialogOpen(true)}
          >
            Delete
          </Button>
          <Button
            type="button"
            variant="contained"
            color="primary"
            onClick={() => setUpdatePolicyDialogOpen(true)}
          >
            Update Policy
          </Button>
        </div>
        <div className="w-full lg:px-8 md:px-6 px-4 flex items-center justify-center py-3">
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            className="w-full"
            sx={{ maxWidth: "600px" }}
          >
            <TextField
              fullWidth
              id="outlined-adornment-weight"
              variant="standard"
              inputProps={{
                maxLength: 15,
              }}
              helperText="Use this to Search Device using IMEI from this 100 Devices"
              label={
                <span className="text-xs sm:text-sm md:text-base lg:text-lg font-medium">
                  Search by IMEI
                </span>
              }
              InputProps={{
                sx: {
                  fontSize: {
                    xs: "12px",
                    sm: "14px",
                    md: "16px",
                    lg: "16px",
                  },
                },
              }}
              onChange={(e) => {
                setSearchIme(e.target.value);
              }}
              value={"" + searchIme}
            />
          </Grid>
        </div>
        <div className="w-full lg:px-8 md:px-6 px-3 flex items-center justify-center py-3">
          <Grid
            className="w-full"
            sx={{ maxWidth: { sm: "400px", xs: "100%" } }}
          >
            <FormControl
              className="w-full"
              sx={{ maxWidth: { sm: "400px", xs: "100%" } }}
              variant="standard"
            >
              <InputLabel
                size="small"
                id="demo-simple-select-label"
                className="text-xs xl:text-sm w-full"
              >
                Enterprises
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                onChange={handleChange}
                className="w-full"
                sx={{
                  fontSize: {
                    xs: "12px",
                    sm: "13px",
                    md: "13px",
                    lg: "16px",
                  },
                  width: "100%",
                }}
              >
                {enterprises.map((enterprise) => (
                  <MenuItem
                    key={enterprise.id}
                    value={enterprise.id}
                    sx={{
                      fontSize: {
                        xs: "12px",
                        sm: "13px",
                        md: "13px",
                        lg: "16px",
                      },
                      width: "100%",
                    }}
                  >
                    {enterprise.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </div>
        <div className="block w-full overflow-x-auto p-4 md:p-8">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-100">
              <tr className="divide-x divide-gray-200">
                <TableHeader heading="S No." />
                <TableHeader heading="IMEI" />
                <TableHeader heading="Serial Number" />
                <TableHeader heading="Enterprise" />
                <TableHeader heading="Device" />
                <TableHeader heading="Model" />
                <TableHeader heading="Policy" />
                <TableHeader heading="Sync" />
                <TableHeader heading="State" />{" "}
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white">
              {devicesFilter.map((device, i) => (
                <tr
                  key={i}
                  className="divide-x divide-gray-200 hover:bg-slate-100"
                >
                  <TableElement value={page * 100 + i + 1} />
                  <TableElement
                    value={
                      <span className="font-semibold">{device?.imei}</span>
                    }
                    type="element"
                  />
                  <TableElement
                    value={
                      <span className="font-semibold">
                        {device?.serialNumber}
                      </span>
                    }
                    type="element"
                  />
                  <TableElement
                    value={
                      <span className="font-semibold">
                        {device?.enterprise}
                      </span>
                    }
                    type="element"
                  />
                  <TableElement
                    value={
                      <span className="font-semibold">{device?.device}</span>
                    }
                    type="element"
                  />
                  <TableElement
                    value={
                      <span className="font-semibold">{device?.model}</span>
                    }
                    type="element"
                  />
                  <TableElement
                    value={
                      <span className="font-semibold">{device?.policy}</span>
                    }
                    type="element"
                  />
                  <TableElement
                    value={
                      <span className="font-semibold">
                        {device?.policyCompliant}
                      </span>
                    }
                    type="boolean"
                  />
                  <TableElement
                    value={
                      <span className="font-semibold">{device?.state}</span>
                    }
                    type="element"
                  />
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {/* Delete Dialog */}
      <Dialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
      >
        <DialogTitle>Delete Device</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Enterprise ID"
            type="text"
            fullWidth
            variant="outlined"
            value={enterpriseId}
            onChange={(e) => setEnterpriseId(e.target.value)}
          />
          <TextField
            margin="dense"
            label="Device ID"
            type="text"
            fullWidth
            variant="outlined"
            value={deviceId}
            onChange={(e) => setDeviceId(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => setConfirmDeleteDialogOpen(true)}
            color="secondary"
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      {/* Confirmation Dialog */}
      <Dialog
        open={confirmDeleteDialogOpen}
        onClose={() => setConfirmDeleteDialogOpen(false)}
      >
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            Are you sure you want to delete this device? This action cannot be
            undone.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setConfirmDeleteDialogOpen(false)}
            color="primary"
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              setConfirmDeleteDialogOpen(false);
              handleDeleteDevice();
            }}
            color="secondary"
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      {/* Update Policy Dialog */}
      <Dialog
        open={updatePolicyDialogOpen}
        onClose={() => setUpdatePolicyDialogOpen(false)}
      >
        <DialogTitle>Update Policy</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Enterprise ID"
            type="text"
            fullWidth
            variant="outlined"
            value={enterpriseId}
            onChange={(e) => setEnterpriseId(e.target.value)}
          />
          <TextField
            margin="dense"
            label="Device ID"
            type="text"
            fullWidth
            variant="outlined"
            value={deviceId}
            onChange={(e) => setDeviceId(e.target.value)}
          />
          <FormControl fullWidth margin="dense">
            <InputLabel id="policy-name-label">Policy Name</InputLabel>
            <Select
              labelId="policy-name-label"
              value={policyName}
              onChange={(e) => setPolicyName(e.target.value)}
              label="Policy Name"
            >
              <MenuItem value="locked">Locked</MenuItem>
              <MenuItem value="unlocked">Unlocked</MenuItem>
              <MenuItem value="uninstalled">Uninstalled</MenuItem>
              <MenuItem value="setup">Setup</MenuItem>
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setUpdatePolicyDialogOpen(false)}
            color="primary"
          >
            Cancel
          </Button>
          <Button onClick={handleUpdatePolicy} color="secondary">
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
